import React,{useState,useEffect} from "react";
import  'bootstrap/dist/css/bootstrap.min.css';
import banner from "../components/home-slider-bg-01.jpg"
import loadable from '@loadable/component'
const Footer = loadable(() => import('../components/Footer'))
const Nav = loadable(() => import ('../components/Nav'))
// const TopNav = loadable(() => import ('../components/TopNav'))
function ASD() {
 
    return (
    <>
    <Nav/>
<section className=" min-height-400px cover-background top-space-verum" style={{backgroundImage: `url(${banner})`}}
>
<div className="container">
    <div className="row">
        <div className="col-12 col-lg-8 ">
            <h3 className="inner-banner-title text-white">Solve Software Engineering<br/>Complexity</h3>
        </div> 
        <div className="col-12 col-lg-4 " data-wow-delay="0.2s"></div>
    </div>
</div>
</section>
<section className="padding-70px-tb ">
<div className="container">
    <div className="row align-items-center justify-content-center text-center">
        <div className="col-12 col-lg-3 margin-30px-bottom">
            <p className="text-large alt-font text-dark-gray margin-15px-bottom">ASD Suite Release</p>
            <p className="margin-30px-bottom">ASD Runtime 9.2.3 Guide<br/>ASD Suite Release 4 v9.2.7</p>
            <a href="../ASD_PDFS/ASD_Runtime_9.2.3_Guide_ASD_Suite_Release_4_v9.2.7.pdf" target='_blank' rel='noopener noreferrer' className="btn btn-green border-radius-5 btn-medium letter-spacing-1">Download</a>
        </div>
        <div className="col-12 col-lg-3 margin-30px-bottom">
            <p className="text-large alt-font text-dark-gray margin-15px-bottom">Release Notes</p>
            <p className="margin-30px-bottom">ASD Suite Release 4 v9.2.7<br/>Release Notes</p>
            <a href="../ASD_PDFS/ASD_Suite_Release_4_v9.2.7_Release_Notes.pdf" target='_blank' rel='noopener noreferrer' className="btn btn-green border-radius-5 btn-medium letter-spacing-1">Download</a>
        </div>
        <div className="col-12 col-lg-3 margin-30px-bottom">
            <p className="text-large alt-font text-dark-gray margin-15px-bottom">User Manual</p>
            <p className="margin-30px-bottom">ASD Suite Release 4 v9.2.7<br/>User Manual</p>


            <a href='../ASD_PDFS/ASD_Suite_Release_4_v9.2.7_User_Manual.pdf' target='_blank' rel='noopener noreferrer' className="btn btn-green border-radius-5 btn-medium letter-spacing-1">Download</a>
        </div>
        <div className="col-12 col-lg-3 margin-30px-bottom">
            <p className="text-large alt-font text-dark-gray margin-15px-bottom">Visual Verification Guide</p>
            <p className="margin-30px-bottom">ASD Suite Release 4 v9.2.7<br/>Visual Verification Guide</p>
            <a href="../ASD_PDFS/ASD_Suite_Release_4_v9.2.7_Visual_Verification_Guide.pdf" target='_blank' rel='noopener noreferrer' className="btn btn-green border-radius-5 btn-medium letter-spacing-1">Download</a>
        </div>
    </div>                               
</div>
</section>
<Footer/>
</>
);
}
export default ASD;
